export default {
  'Items por página' : 'Items por página',
  de: 'de',
  'y': 'y',
  English : 'Inglés',
  Portuguese : 'Portugués',
  Spanish: 'Español',
  Salir: 'Salir',
  Usuarios: 'Usuarios',
  Usuario: 'Usuario',
  Paises: 'Paises',
  'Brasil' : 'Brasil',
  'República Dominicana' : 'República Dominicana',
  'Granada' : 'Granada',
  'Guayana Francesa' : 'Guayana Francesa',
  'Guyana' : 'Guayana',
  País: 'País',
  Regiones: 'Regiones',
  Region: 'Región',
  Escuelas: 'Escuelas',
  Escuela: 'Escuela',
  Perfil: 'Perfil',
  Evaluación: 'Evaluación',
  "Estadísticas": 'Estadísticas',
  "Por evaluación": 'Por evaluación',
  "Por escuela": 'Por escuela',
  "Por continente": 'Por continente',
  "Por país": 'Por país',
  "Por región": 'Por región',
  "Latinoamerica y el Caribe": 'Latinoamerica y el Caribe',
  'Paises asignados' : 'Paises asignados',
  'Regiones asignadas' : 'Regiones asignadas',
  'Escuelas asignadas' : 'Paises asignadas',
  'PQI Evaluation': 'Evaluaciones PQI',
  'All rights Reserved.' : 'Todos los derechos reservados.',
  'Powered by' : 'Desarrollado por',
  Home : 'Home',
  'Ir al contenido' : 'Ir al contenido',
  FAQs : 'FAQs',
  Tutorial : 'Tutorial',
  Evaluaciones: 'Evaluaciones',
  Resultados: 'Resultados',
  Período: 'Período',
  Título: 'Título',
  Nota: 'Nota',
  Instrumentos: 'Instrumentos',
  Instrumento: 'Instrumento',
  'Crear Usuario': 'Crear Usuario',
  'Editar Usuario': 'Editar Usuario',
  'Editar información de Usuario': 'Editar información de Usuario',
  'Crear Region': 'Crear Región',
  'Editar Region': 'Editar Region',
  'Crear Escuela': 'Crear Escuela',
  'Crear Evaluación': 'Crear Evaluación',
  'Eliminar evaluación' : 'Eliminar evaluación',
  Nombre: 'Nombre',
  Files: 'Archivos',
  Add: 'Agregar',
  Close: 'Cerrar',
  'Close modal' : 'Cerrar modal',
  Descargar: 'Descargar',
  Acciones: 'Acciones',
  Editar: 'Editar',
  Crear: 'Crear',
  Opciones: 'Opciones',
  'Opción': 'Opción',
  Modalidad: 'Modalidad',
  Nivel: 'Nivel',
  Puntaje: 'Puntaje',
  'Ayuda': 'Ayuda',
  'Imprimir gráficos' : 'Imprimir gráficos',
  'General': 'General',
  'Por categoria': 'Por categoria',
  'Por indicador': 'Por indicador',
  'Plan de acción' : 'Plan de acción',
  'Tipo de gestión': 'Tipo de gestión',
  'Resultados generales': 'Resultados generales',
  'Puntaje de la categoría': 'Puntaje de la categoría',
  'Puntaje del indicador' : 'Puntaje del indicador',
  'Distribución de los criterios de valoración': 'Distribución de los criterios de valoración',
  Rol: 'Rol',
  'Teléfono': 'Telefono',
  'Buscar por nombre de usuario': 'Buscar por nombre de usuario',
  'Buscar por nombre de region': 'Buscar por nombre de región',
  'Buscar por nombre de país': 'Buscar por nombre de país',
  'Buscar por nombre de escuela': 'Buscar por nombre de escuela',
  'Buscar por nombre de evaluación': 'Buscar por nombre de evaluación',
  'Nombre completo': 'Nombre completo',
  'Nombre de usuario': 'Nombre de usuario',
  Contraseña: 'Contraseña',
  'Contraseña anterior' : 'Contraseña anterior',
  'Contraseña nueva' : 'Contraseña nueva',
  'Repetir la nueva contraseña' : 'Repetir la nueva contraseña',
  'Confirmar contraseña': 'Confirmar contraseña',
  '¿Desea recibir emails automaticamente?': '¿Desea recibir emails automaticamente?',
  'No, gracias': 'No, gracias',
  Suscribirse: 'Suscribirse',
  Cancelar: 'Cancelar',
  Siguiente: 'Siguiente',
  Date: 'Fecha',
  Semana: 'Semana',
  Semana: 'Semana',
  Detalles: 'Detalles',
  Agregar: 'Agregar',
  Ver: 'Ver',
  Upload: 'Subir',
  Guardar: 'Guardar',
  Cerrar: 'Cerrar',
  Asignar: 'Asignar',
  'Progreso': 'Progreso',
  'Asignar todas': 'Asignar todas',
  'Asignar selección': 'Asignar selección',
  Filtrar: 'Filtrar',
  Finalizar: 'Finalizar',
  'No hay paises asignados para este usuario.' : 'No hay paises asignados para este usuario.',
  'No hay regiones asignadas para este usuario.' : 'No hay regiones asignadas para este usuario.',
  'No hay escuelas asignadas para este usuario.': 'No hay escuelas asignadas para este usuario.',
  'Sin información disponible' : 'Sin información disponible',
  'Cambiar Contraseña': 'Cambiar Contraseña',
  'Cambiar contraseña de usuario' : 'Cambiar contraseña de usuario',
  'Selecciona...': 'Selecciona...',
  'Seleccione un archivo' : 'Seleccione un archivo',
  'Campo requerido': 'Campo requerido',
  'Email inválido': 'Email inválido',
  'Nombre de la Region': 'Nombre de la región',
  Administrador: 'Administrador',
  'Representante de país': 'Representante de país',
  'Coordinador regional': 'Coordinador regional',
  'Representante escolar': 'Representante escolar',
  'Nombre de la Escuela': 'Nombre de la Escuela',
  'Nombre del proyecto': 'Nombre del proyecto',
  'Profesiones': 'Profesiones',
  'Cantidad de estudiantes': 'Cantidad de estudiantes',
  'Estudiantes con discapacidades': 'Estudiantes con discapacidades',
  'Estudiantes con multiple discapacidades': 'Estudiantes con multiple discapacidades',
  'Estudiantes con discapacidades visuales': 'Estudiantes con discapacidades visuales',
  'Estudiantes con sordoceguera': 'Estudiantes con sordoceguera',
  'Cantidad de profesores': 'Cantidad de profesores',
  'Cantidad de otros profesionales': 'Cantidad de otros profesionales',
  'Personal total': 'Personal total',
  'Educación regular': 'Educación regular',
  'Educación especial': 'Educación especial',
  'Educación domiciliaria hospitalaria': 'Educación domiciliaria hospitalaria',
  'Centro de primera infancia': 'Centro de primera infancia',
  'Público': 'Público',
  'Privado': 'Privado',
  'Preescolar': 'Preescolar',
  'Escolar primario': 'Escolar primario',
  'Escolar secundario': 'Escolar secundario',
  'Instrumento Escolar': 'Instrumento Escolar',
  'Instrumento Preescolar': 'Instrumento Preescolar',
  '¿Estás seguro de eliminar el usuario': '¿Estás seguro de eliminar el usuario',
  '¿Estás seguro de eliminar la region': '¿Estás seguro de eliminar la region',
  '¿Estás seguro de eliminar la escuela': '¿Estás seguro de eliminar la escuela',
  '¿Estás seguro de eliminar esta evaluación' : '¿Estás seguro de eliminar esta evaluación',
  'Eliminar': 'Eliminar',
  'No hay datos disponibles.': 'No hay datos disponibles.',
  Indicadores: 'Indicadores',
  'Prácticas-autonomía': 'Prácticas-autonomía',
  'Trabajo colaborativo': 'Trabajo colaborativo',
  'Comunicación transversal': 'Comunicación transversal',
  'Inclusión educativa': 'Inclusión educativa',
  'Debe cargar algún indicador' : 'Debe cargar algun indicador',
  'para poder visualizar gráficos' : 'Para poder visualizar gráficos',
  Comentarios: 'Comentarios',
  'Comentario completo': 'Comentario completo',
  'Conocimiento sobre el estudiante': 'Conocimiento sobre el estudiante',
  'Planificación actividades y contenidos': 'Planificación actividades y contenidos',
  'Organización del entorno educativo': 'Organización del entorno educativo',
  'Gestión escolar': 'Gestión escolar',
  'Sobre los profesionales': 'Sobre los profesionales',
  'Sobre la participación de los estudiantes': 'Sobre la participación de los estudiantes',
  'Sobre la familia': 'Sobre la familia',
  'Información del niño/niña' : 'Información del niño/niña',
  'Programa en educación temprana: planificación y actividades' : 'Programa en educación temprana: planificación y actividades',
  'Organización del entorno' : 'Organización del entorno',
  'Gestión del servicio' : 'Gestión del servicio',
  'Prácticas profesionales' : 'Prácticas profesionales',
  'Interacciones de calidad entre niño/niña y adulto' : 'Interacciones de calidad entre niño/niña y adulto',
  'Participación de la familia' : 'Participación de la familia',

  //schoolIndicatorParser:
  'El equipo de profesionales trabaja de forma apropiada con el/la estudiante, en un proceso de valoración colaborativa y coordinada, demostrando estar formado acerca del impacto de la discapacidad múltiple y/o sordoceguera e interactuando comunicativamente de forma apropiada.': 'El equipo de profesionales trabaja de forma apropiada con el/la estudiante, en un proceso de valoración colaborativa y coordinada, demostrando estar formado acerca del impacto de la discapacidad múltiple y/o sordoceguera e interactuando comunicativamente de forma apropiada.',
  'Las evaluaciones sensoriales funcionales (visión y audición) se completan en cada niño.': 'Las evaluaciones sensoriales funcionales (visión y audición) se completan en cada niño.',
  'El personal del programa coordina o facilita evaluaciones de visión y audición con especialistas cualificados en atención ocular o auditiva (oftalmólogo, optometrista, otolaringólogo, etc.).': 'El personal del programa coordina o facilita evaluaciones de visión y audición con especialistas cualificados en atención ocular o auditiva (oftalmólogo, optometrista, otolaringólogo, etc.).',
  'Las evaluaciones se realizan durante rutinas naturales permitiendo observar las interacciones espontáneas del niño.': 'Las evaluaciones se realizan durante rutinas naturales permitiendo observar las interacciones espontáneas del niño.',
  'Los resultados de la evaluación son revisados periódicamente y se utilizan de forma actualizada en la planificación y en el seguimiento.': 'Los resultados de la evaluación son revisados periódicamente y se utilizan de forma actualizada en la planificación y en el seguimiento.',
  'Las planificaciones diarias, las actividades y las estrategias de enseñanza están claramente relacionadas con las metas, los objetivos y los intereses de los estudiantes incluidos en el PEI.': 'Las planificaciones diarias, las actividades y las estrategias de enseñanza están claramente relacionadas con las metas, los objetivos y los intereses de los estudiantes incluidos en el PEI.',
  'Las actividades del aula incluyen áreas del currículo expandido y están alineadas con el currículo nacional.': 'Las actividades del aula incluyen áreas del currículo expandido y están alineadas con el currículo nacional.',
  'Las actividades y contenidos se proponen a través de situaciones problemáticas que motivan al estudiante a participar, compartir y hacer elecciones.': 'Las actividades y contenidos se proponen a través de situaciones problemáticas que motivan al estudiante a participar, compartir y hacer elecciones.',
  'Hay tiempo suficiente para las transiciones entre actividades que respeten las habilidades individuales y permitan la comprensión y participación tranquila de los estudiantes.': 'Hay tiempo suficiente para las transiciones entre actividades que respeten las habilidades individuales y permitan la comprensión y participación tranquila de los estudiantes.',
  'TIEMPO: El día se organiza de manera fluida, conectando las actividades entre sí (por ejemplo, escribir una lista de artículos que se deben comprar para una actividad de cocina, ir a la tienda a comprarlos, completar la actividad de cocina, escribir sobre ella en un diario).': 'TIEMPO: El día se organiza de manera fluida, conectando las actividades entre sí (por ejemplo, escribir una lista de artículos que se deben comprar para una actividad de cocina, ir a la tienda a comprarlos, completar la actividad de cocina, escribir sobre ella en un diario).',
  'Hay un horario claramente definido, consistente y accesible para los estudiantes.': 'Hay un horario claramente definido, consistente y accesible para los estudiantes.',
  'CONTEXTO: El espacio y las áreas de trabajo, son seguros y adaptados a las necesidades de apoyo derivadas de la discapacidad (por ej: para la discapacidad visuales: iluminación, contraste, espacios definidos, etc.; para la pérdida de audición, revestimiento del suelo y de las ventanas para reducir el ruido).': 'CONTEXTO: El espacio y las áreas de trabajo, son seguros y adaptados a las necesidades de apoyo derivadas de la discapacidad (por ej: para la discapacidad visuales: iluminación, contraste, espacios definidos, etc.; para la pérdida de audición, revestimiento del suelo y de las ventanas para reducir el ruido).',
  'Se controla el umbral sensorial en el entorno para fomentar la atención y el máximo acceso al aprendizaje.': 'Se controla el umbral sensorial en el entorno para fomentar la atención y el máximo acceso al aprendizaje.',
  'Se utilizan múltiples ubicaciones en la escuela y dentro de la comunidad para fomentar la enseñanza en entornos naturales.': 'Se utilizan múltiples ubicaciones en la escuela y dentro de la comunidad para fomentar la enseñanza en entornos naturales.',
  'Los materiales didácticos son apropiados para las edades y los niveles de desarrollo de los estudiantes y son accesibles para su uso en el aula.': 'Los materiales didácticos son apropiados para las edades y los niveles de desarrollo de los estudiantes y son accesibles para su uso en el aula.',
  'Los miembros del personal demuestran saber cómo ayudar a los estudiantes anticipando y hablando sobre las actividades. Por ejemplo, usando calendarios, proporcionando un nivel apropiado de asistencia y cantidad adecuada de pistas para completar una tarea.': 'Los miembros del personal demuestran saber cómo ayudar a los estudiantes anticipando y hablando sobre las actividades. Por ejemplo, usando calendarios, proporcionando un nivel apropiado de asistencia y cantidad adecuada de pistas para completar una tarea.',
  'Hay suficiente personal docente representando a los diferentes niveles de gestión para atender las necesidades de aprendizaje de cada niño.': 'Hay suficiente personal docente representando a los diferentes niveles de gestión para atender las necesidades de aprendizaje de cada niño.',
  'Hay servicios de apoyo organizados para atender las necesidades educativas del alumno (fisioterapia, orientación y movilidad, etc.).': 'Hay servicios de apoyo organizados para atender las necesidades educativas del alumno (fisioterapia, orientación y movilidad, etc.).',
  'El personal recibe supervisión y apoyo de forma organizada por alguien con conocimientos y habilidades en la educación de niños con DM/DV/SC.': 'El personal recibe supervisión y apoyo de forma organizada por alguien con conocimientos y habilidades en la educación de niños con DM/DV/SC.',
  'Hay oportunidades planificadas para que todo el personal, incluidos los padres, equipo de paraprofesionales y administradores participen en actividades de formación e intercambio de información sobre cómo se trabaja y da respuesta a las necesidades de estudiantes con SC/DM/DS.': 'Hay oportunidades planificadas para que todo el personal, incluidos los padres, equipo de paraprofesionales y administradores participen en actividades de formación e intercambio de información sobre cómo se trabaja y da respuesta a las necesidades de estudiantes con SC/DM/DS.',
  'Hay un sistema eficaz de comunicación entre el hogar y la escuela (visitas al hogar, libros de comunicación, etc.) para que el personal y las familias puedan mantenerse bien informados sobre el alumno.': 'Hay un sistema eficaz de comunicación entre el hogar y la escuela (visitas al hogar, libros de comunicación, etc.) para que el personal y las familias puedan mantenerse bien informados sobre el alumno.',
  'Los administradores del programa interactúan con los funcionarios a nivel local, regional y nacional que son responsables de la supervisión del programa.': 'Los administradores del programa interactúan con los funcionarios a nivel local, regional y nacional que son responsables de la supervisión del programa.',
  'Los Planes Educativos Individualizados (PEI) están documentados y tienen un formato específico para su registro.': 'Los Planes Educativos Individualizados (PEI) están documentados y tienen un formato específico para su registro.',
  'FORMACIÓN EN COMUNICACIÓN: El personal entiende, reconoce y responde a los modos de comunicación más naturales de sus estudiantes, mostrando una relación cómoda y mutuamente agradable.': 'FORMACIÓN EN COMUNICACIÓN: El personal entiende, reconoce y responde a los modos de comunicación más naturales de sus estudiantes, mostrando una relación cómoda y mutuamente agradable.',
  'El personal es capaz de modelar el siguiente nivel superior de comunicación fomentando el desarrollo de la comunicación de sus estudiantes.': 'El personal es capaz de modelar el siguiente nivel superior de comunicación fomentando el desarrollo de la comunicación de sus estudiantes.',
  'El personal entiende la intención comunicativa de los comportamientos desafiantes en los estudiantes y responde de forma adecuada.': 'El personal entiende la intención comunicativa de los comportamientos desafiantes en los estudiantes y responde de forma adecuada.',
  'TRABAJO EN EQUIPO: Los profesores y administradores se comunican claramente entre sí.': 'TRABAJO EN EQUIPO: Los profesores y administradores se comunican claramente entre sí.',
  'El personal trabaja cooperativamente desde sus roles disciplinarios y comparten responsabilidades para el desarrollo de planes educativos.': 'El personal trabaja cooperativamente desde sus roles disciplinarios y comparten responsabilidades para el desarrollo de planes educativos.',
  'Los estudiantes tienen acceso a los apoyos comunicativos durante todo el día.': 'Los estudiantes tienen acceso a los apoyos comunicativos durante todo el día.',
  'Cada estudiante participa plenamente en todas las actividades, independientemente de su nivel de desarrollo.': 'Cada estudiante participa plenamente en todas las actividades, independientemente de su nivel de desarrollo.',
  'El estudiante se manifiesta motivado, atento e involucrado en la actividad que se le propone.': 'El estudiante se manifiesta motivado, atento e involucrado en la actividad que se le propone.',
  'El estudiante reconoce y es reconocido por el resto de sus compañeros.': 'El estudiante reconoce y es reconocido por el resto de sus compañeros.',
  'Se comparte un sistema comunicativo en común entre los compañeros promoviendo sostener interacciones y lazos de amistad.': 'Se comparte un sistema comunicativo en común entre los compañeros promoviendo sostener interacciones y lazos de amistad.',
  'El estudiante usa los diferentes niveles de apoyo disponibles para participar.': 'El estudiante usa los diferentes niveles de apoyo disponibles para participar.',
  'Los estudiantes colaboran entre sí.': 'Los estudiantes colaboran entre sí.',
  'Se fomenta que los miembros de la familia compartan información y expresen sus expectativas durante el proceso de evaluación, generando una comunicación fluida y cercana, y permitiendo una toma de decisiones equitativa y cooperativa.': 'Se fomenta que los miembros de la familia compartan información y expresen sus expectativas durante el proceso de evaluación, generando una comunicación fluida y cercana, y permitiendo una toma de decisiones equitativa y cooperativa.',
  'Hay actividades coordinadas que promueven la creación de redes entre las familias.': 'Hay actividades coordinadas que promueven la creación de redes entre las familias.',
  'Las familias participan activamente en las actividades del programa.': 'Las familias participan activamente en las actividades del programa.',
  'El personal del programa se reúne con las familias en sus hogares y/o en la escuela de forma regular.': 'El personal del programa se reúne con las familias en sus hogares y/o en la escuela de forma regular.',
  'Se ofrecen talleres de formación para las familias que tienen hijos en el programa, incluyendo apoyo e instrucción en estrategias de comunicación eficaces para su hijo.': 'Se ofrecen talleres de formación para las familias que tienen hijos en el programa, incluyendo apoyo e instrucción en estrategias de comunicación eficaces para su hijo.',

  //preschoolIndicatorParser:
  'El equipo de profesionales trabaja de forma apropiada con cada niño/a, en un proceso de valoración colaborativa y coordinada, demostrando estar formado acerca del impacto de la discapacidad múltiple y/o sordoceguera e interactuando comunicativamente de forma apropiada.' : 'El equipo de profesionales trabaja de forma apropiada con cada niño/a, en un proceso de valoración colaborativa y coordinada, demostrando estar formado acerca del impacto de la discapacidad múltiple y/o sordoceguera e interactuando comunicativamente de forma apropiada.',
  'Las evaluaciones funcionales (sensoriales y motrices) se completan en cada niño/a.' : 'Las evaluaciones funcionales (sensoriales y motrices) se completan en cada niño/a.',
  'El personal del programa de educación temprana, coordina o facilita un trabajo en red sobre evaluaciones con especialistas cualificados en atención ocular o auditiva (oftalmólogo, optometrista, otolaringólogo, etc.).' : 'El personal del programa de educación temprana, coordina o facilita un trabajo en red sobre evaluaciones con especialistas cualificados en atención ocular o auditiva (oftalmólogo, optometrista, otolaringólogo, etc.).',
  'Los resultados de la evaluación son revisados periódicamente (por ejemplo cada 3/6 meses) y se utilizan de forma actualizada en la planificación y en el seguimiento.' : 'Los resultados de la evaluación son revisados periódicamente (por ejemplo cada 3/6 meses) y se utilizan de forma actualizada en la planificación y en el seguimiento.',
  'La organización del programa incentiva el juego (variados y adecuados a la edad) y las interacciones sociales para alcanzar logros en las diferentesáreas de desarrollo.' : 'La organización del programa incentiva el juego (variados y adecuados a la edad) y las interacciones sociales para alcanzar logros en las diferentesáreas de desarrollo.',
  'Se ofrecen rutinas diarias a través de las cuales el niño/niña puedan anticipar distintas actividades en diferentes espacios' : 'Se ofrecen rutinas diarias a través de las cuales el niño/niña puedan anticipar distintas actividades en diferentes espacios',
  'Se integran las rutinas básicas de alimentación, cuidado e higiene para el desarrollo de habilidades sociales.' : 'Se integran las rutinas básicas de alimentación, cuidado e higiene para el desarrollo de habilidades sociales.',
  'Las actividades proponen el aprendizaje a traves de los diferentes sentidos.' : 'Las actividades proponen el aprendizaje a traves de los diferentes sentidos.',
  'Los miembros del personal demuestran saber cómo ayudar a los estudiantes proporcionando apropiado nivel de asistencia y promoviendo la independencia y la seguridad para la participación de los niños/niñas.' : 'Los miembros del personal demuestran saber cómo ayudar a los estudiantes proporcionando apropiado nivel de asistencia y promoviendo la independencia y la seguridad para la participación de los niños/niñas.',
  'TIEMPO: Hay una rutina consistente en la organización de la jornada diaria.' : 'TIEMPO: Hay una rutina consistente en la organización de la jornada diaria.',
  'El día se organiza de manera fluida conectando las actividades.' : 'El día se organiza de manera fluida conectando las actividades.',
  'CONTEXTO: El espacio y las áreas de trabajo, son seguros y adaptados a las necesidades de apoyo derivadas de la discapacidad (por ej:para la discapacidad visuales: iluminación, contraste, espacios definidos, etc.; para la pérdida de audición, revestimiento del suelo y de las ventanas para reducir el ruido; para las discapacidades físicas accesibilidad en el entorno)' : 'CONTEXTO: El espacio y las áreas de trabajo, son seguros y adaptados a las necesidades de apoyo derivadas de la discapacidad (por ej:para la discapacidad visuales: iluminación, contraste, espacios definidos, etc.; para la pérdida de audición, revestimiento del suelo y de las ventanas para reducir el ruido; para las discapacidades físicas accesibilidad en el entorno)',
  'Se utilizan múltiples ubicaciones en el servicio y dentro de la comunidad para fomentar la enseñanza en entornos naturales y apropiados.' : 'Se utilizan múltiples ubicaciones en el servicio y dentro de la comunidad para fomentar la enseñanza en entornos naturales y apropiados.',
  'Los materiales utilizados en las actividades (rotulados, con contraste, texturas agradables, etc) proponen, a partir de su disposición y caracteríticas, la exploración activa y la acción del niño sobre el objeto.' : 'Los materiales utilizados en las actividades (rotulados, con contraste, texturas agradables, etc) proponen, a partir de su disposición y caracteríticas, la exploración activa y la acción del niño sobre el objeto.',
  'Hay suficiente personal, representando a los diferentes niveles de gestión, para atender las necesidades de aprendizaje de cada niño.' : 'Hay suficiente personal, representando a los diferentes niveles de gestión, para atender las necesidades de aprendizaje de cada niño.',
  'Hay oportunidades planificadas para que toda la comunidad educativa participe en actividades formación e intercambio sobre como trabajar y dar respuesta a las necesidades de los niños/niñas con DM/DV/SC' : 'Hay oportunidades planificadas para que toda la comunidad educativa participe en actividades formación e intercambio sobre como trabajar y dar respuesta a las necesidades de los niños/niñas con DM/DV/SC',
  'Hay un sistema eficaz de comunicación entre el hogar y el servicio (visitas al hogar, libros de comunicación…) para que el personal y las familias puedan mantenerse bien informados sobre el niño/niña' : 'Hay un sistema eficaz de comunicación entre el hogar y el servicio (visitas al hogar, libros de comunicación…) para que el personal y las familias puedan mantenerse bien informados sobre el niño/niña',
  'Los administradores del servicio trabaja en red con organizaciones, ministerios u otros programas para organizar la transición de los niños/niñas a otros servicios.' : 'Los administradores del servicio trabaja en red con organizaciones, ministerios u otros programas para organizar la transición de los niños/niñas a otros servicios.',
  'Existen planes individualizados documentados con un formato específico en el que participen colaborativamente entre todos los miembros del equipo' : 'Existen planes individualizados documentados con un formato específico en el que participen colaborativamente entre todos los miembros del equipo',
  'El personal brinda oportunidades para que el niño o niña pueda realizar elecciones a lo largo de la jornada.' : 'El personal brinda oportunidades para que el niño o niña pueda realizar elecciones a lo largo de la jornada.',
  'El personal brinda oportunidades para que el niño o niña inicie la comunicación como por ejemplo la toma de turno, pedir más.' : 'El personal brinda oportunidades para que el niño o niña inicie la comunicación como por ejemplo la toma de turno, pedir más.',
  'TRABAJO EN EQUIPO: Los profesores y administradores se comunican claramente entre sí. Por ejemplo comparten información relacionada de los niños/niñas para trabajar de forma consistente y coordinada' : 'TRABAJO EN EQUIPO: Los profesores y administradores se comunican claramente entre sí. Por ejemplo comparten información relacionada de los niños/niñas para trabajar de forma consistente y coordinada',
  'El personal trabaja cooperativamente desde sus roles disciplinarios y comparten responsabilidades para el desarrollo de planes educativos y para la transición a los futuros servicios preescolares.' : 'El personal trabaja cooperativamente desde sus roles disciplinarios y comparten responsabilidades para el desarrollo de planes educativos y para la transición a los futuros servicios presecolares.',
  'Las interacciones entre niño/niña y adulto son saludables, satisfactorias y en un clima amigable (el adulto y/o figura de apego propone interacciones que afianzan el vínculo destacando los aspectos positivos y alentando a la participación).' : 'Las interacciones entre niño/niña y adulto son saludables, satisfactorias y en un clima amigable (el adulto y/o figura de apego propone interacciones que afianzan el vínculo destacando los aspectos positivos y alentando a la participación)',
  'Cada niño/niña participa en todas las actividades, independientemente de su nivel de desarrollo' : 'Cada niño/niña participa en todas las actividades, independientemente de su nivel de desarrollo',
  'El niño/niña reconoce y es reconocido por el resto de sus compañeros.' : 'El niño/niña reconoce y es reconocido por el resto de sus compañeros.',
  'Los niños/as comparten un sistema comunicativo en común, promoviendo interacciones y lazos de amistad entre pares.' : 'Los niños/as comparten un sistema comunicativo en común, promoviendo interacciones y lazos de amistad entre pares.',
  'Se les propone a los niños y niñas modelos de comunicación apropiados para cada uno/una.' : 'Se les propone a los niños y niñas modelos de comunicación apropiados para cada uno/una.',
  'Se involucra a las familias en la implementación de los planes y en la organización de la transición de los niños/niñas a los nuevos servicios.' : 'Se involucra a las familias en la implementación de los planes y en la organización de la transición de los niños/niñas a los nuevos servicios.',
  'Las familias implementan en sus casas estrategias y orientaciones brindadas por el programa' : 'Las familias implementan en sus casas estrategias y orientaciones brindadas por el programa',

  //Action-plan
  'Esta evaluación no tiene un plan de acción' : 'Esta evaluación no tiene un plan de acción',
  '¿Cuáles son las necesidades y problemas identificados para atender prioritariamente en el proceso de cambio sobre el que se realiza el plan?' : '¿Cuáles son las necesidades y problemas identificados para atender prioritariamente en el proceso de cambio sobre el que se realiza el plan?',
  '¿Cuáles son los centros de interés que motivan y movilizan al equipo de trabajo para participar del proceso de cambio?' : '¿Cuáles son los centros de interés que motivan y movilizan al equipo de trabajo para participar del proceso de cambio?',
  '¿Cuáles son los obstáculos y las dificultades que se pueden encontrar al momento de implementar el plan de acción para resolver los problemas identificados?' : '¿Cuáles son los obstáculos y las dificultades que se pueden encontrar al momento de implementar el plan de acción para resolver los problemas identificados?',
  '¿De qué recursos disponemos en el corto, mediano y largo plazo para llevar adelante un plan de cambio?' : '¿De qué recursos disponemos en el corto, mediano y largo plazo para llevar adelante un plan de cambio?',
  '¿Cuáles son los resultados de la evaluación de los indicadores de calidad de Perkins que se deciden priorizar en el plan de acción?' : '¿Cuáles son los resultados de la evaluación de los indicadores de calidad de Perkins que se deciden priorizar en el plan de acción?',
  'Meta a cumplir': 'Meta a cumplir',
  'Resultado esperado': 'Resultado esperado',
  'Actividades para cumplir meta': 'Actividades para cumplir meta',
  'Editando': 'Editando',
  'Estado' : 'Estado',
  'Realizado' : 'Realizado',
  'En progreso' : 'En progreso',
  'No realizado' : 'No realizado',
  'Completar campo': 'Completar campo',
  'No hay información aun cargada': 'No hay información aun cargada',
  'Aún no hay actividades asignadas': 'Aún no hay actividades asignadas',
  'Aún no hay observaciones': 'Aún no hay observaciones',
  'Meta' : 'Meta',
  'Observaciones' : 'Observaciones',
  'Fecha': 'Fecha',
  'Agregar meta' : 'Agregar meta',
  'Imprimir' : 'Imprimir',
  'Presencial': 'Presencial',
  'Virtual': 'Virtual',
  'Reuniones de seguimiento.': 'Reuniones de seguimiento.',
  'Comunicaciones por e-mail, WhatsApp.': 'Comunicaciones por e-mail, WhatsApp.',
  'Monitoreo sobre la implementación de contenidos recibidos desde la formación de la academia.': 'Monitoreo sobre la implementación de contenidos recibidos desde la formación de la academia.',
  'Monitoreo sobre actividades singulares/distintivas, prácticas referidas a casos individuales.': 'Monitoreo sobre actividades singulares/distintivas, prácticas referidas a casos individuales.',
  'Otras formaciones.': 'Otras formaciones.',
  'Otras actividades.': 'Otras actividades.',
  'Actividad': 'Actividad',
  'Próximos acuerdos': 'Próximos acuerdos',
  'Categoría': 'Categoría',

  'Las puntuaciones deben sumar 100': 'Las puntuaciones deben sumar 100',
  'Puntuaciones guardadas con éxito': 'Puntuaciones guardadas con éxito',
  'Peso teórico': 'Peso teórico',
  'No aplica': 'No aplica',
  'No se encuentra evidencia': 'No se encuentra evidencia',
  'Focal': 'Focal',
  'En expansión': 'En expansión',
  'Transversal': 'Transversal',
  'Política institucional': 'Política institucional',
  'Cultura innovadora': 'Cultura innovadora',
  'Indicadores de calidad de Perkins':'Indicadores de calidad de Perkins',
  'Estos indicadores son el resultado de una extensa discusión entre el personal profesional de Perkins Internacional y respetados colegas de diferentes regiones donde este programa presta servicios.': 'Estos indicadores son el resultado de una extensa discusión entre el personal profesional de Perkins Internacional y respetados colegas de diferentes regiones donde este programa presta servicios.',
  'Esta versión de 2021 para Latinoamérica surge de la iniciativa por llevar adelante un proceso de validación y actualización en función de los cambios de paradigma, la experiencia ganada por el equipo de especialistas y de la diversidad de entornos y participantes que están involucrados en las diferentes experiencias educativas con estudiante con sordoceguera, discapacidad sensorial y/o discapacidad múltiple.': 'Esta versión de 2021 para Latinoamérica surge de la iniciativa por llevar adelante un proceso de validación y actualización en función de los cambios de paradigma, la experiencia ganada por el equipo de especialistas y de la diversidad de entornos y participantes que están involucrados en las diferentes experiencias educativas con estudiante con sordoceguera, discapacidad sensorial y/o discapacidad múltiple.',
  'La perspectiva de revisión del instrumento es la que entiende a la evaluación como un proceso inherente a la educación que aporta una mirada crítica y reflexiva a la experiencia educativa. El resultado de la evaluación no marca un lugar de certezas absolutas, sino que permite analizar la experiencia educativa en su complejidad, entendida como una intersección de acciones, de implementación de estrategias y proyecciones': 'La perspectiva de revisión del instrumento es la que entiende a la evaluación como un proceso inherente a la educación que aporta una mirada crítica y reflexiva a la experiencia educativa. El resultado de la evaluación no marca un lugar de certezas absolutas, sino que permite analizar la experiencia educativa en su complejidad, entendida como una intersección de acciones, de implementación de estrategias y proyecciones',
  'Se espera que esta herramienta sea utilizada por los programas para la autorreflexión y autoevaluación, como parte del proceso de desarrollo de sus planes internos de formación y desarrollo del programa.': 'Se espera que esta herramienta sea utilizada por los programas para la autorreflexión y autoevaluación, como parte del proceso de desarrollo de sus planes internos de formación y desarrollo del programa.',
  'Asimismo, también puede ser utilizada como una herramienta para los evaluadores externos, para medir la capacidad de un programa, desarrollar un plan para apoyar el futuro y calcular el impacto de este apoyo en la construcción de capacidad.': 'Asimismo, también puede ser utilizada como una herramienta para los evaluadores externos, para medir la capacidad de un programa, desarrollar un plan para apoyar el futuro y calcular el impacto de este apoyo en la construcción de capacidad.',
  'Principios': 'Principios',
  'LOS PRINCIPIOS ORIENTADORES DE ESTA REVISIÓN SON': 'LOS PRINCIPIOS ORIENTADORES DE ESTA REVISIÓN SON',
  'Prácticas Promotoras de Autonomía': 'Prácticas Promotoras de Autonomía',
  'Experiencias educativas que, por su valor innovador y transformador en la implementación de estrategias, disponibilidad de material, accesibilidad universal responden a las necesidades y demandas de los estudiantes para el desarrollo de su autonomía y participación.': 'Experiencias educativas que, por su valor innovador y transformador en la implementación de estrategias, disponibilidad de material, accesibilidad universal responden a las necesidades y demandas de los estudiantes para el desarrollo de su autonomía y participación.',
  'Trabajo Colaborativo': 'Trabajo Colaborativo',
  'Dinámicas de trabajo que convocan a la participación de maestros, autoridades y familia, cuyas metas y estrategias son diseñadas e implementadas conjuntamente para lograr un trabajo articulado.': 'Dinámicas de trabajo que convocan a la participación de maestros, autoridades y familia, cuyas metas y estrategias son diseñadas e implementadas conjuntamente para lograr un trabajo articulado.',
  'Comunicación Transversal (principio y método)': 'Comunicación Transversal (principio y método)',
  'Se considera a la comunicación como una filosofía de trabajo y como una estrategia metodológica.': 'Se considera a la comunicación como una filosofía de trabajo y como una estrategia metodológica.',
  'Como filosofía de trabajo: significa considerarla fundamental para el desarrollo del aprendizaje y las relaciones interpersonales, por lo cual se debe estudiar y planificar en todas las áreas de participación de los estudiantes.': 'Como filosofía de trabajo: significa considerarla fundamental para el desarrollo del aprendizaje y las relaciones interpersonales, por lo cual se debe estudiar y planificar en todas las áreas de participación de los estudiantes.',
  'Como estrategia de trabajo: considerarla a través de decisiones metodológicas en la implementación de dinámicas, diseño de materiales y gestión de apoyos, dando respuesta a las diversidades funcionales de los estudiantes.': 'Como estrategia de trabajo: considerarla a través de decisiones metodológicas en la implementación de dinámicas, diseño de materiales y gestión de apoyos, dando respuesta a las diversidades funcionales de los estudiantes.',
  'Inclusión Educativa': 'Inclusión Educativa',
  'Se trata de una cultura educativa basada en garantizar la igualdad de oportunidades y circunstancias para el aprendizaje y la continuidad de los estudiantes en el entorno escolar. Se toman decisiones en diferentes niveles: práctica educativa, política educativa y cultura educativa, procurando evitar los riesgos de exclusión.': 'Se trata de una cultura educativa basada en garantizar la igualdad de oportunidades y circunstancias para el aprendizaje y la continuidad de los estudiantes en el entorno escolar. Se toman decisiones en diferentes niveles: práctica educativa, política educativa y cultura educativa, procurando evitar los riesgos de exclusión.',
  'Objetivos de la implementación de los indicadores de calidad': 'Objetivos de la implementación de los indicadores de calidad',
  'Identificar': 'Identificar',
  'aquellas prácticas educativas que dan como resultado el logro de los objetivos buscados, definiendo el nivel de impacto en relación a las posibilidades que dan para la participación del estudiante con DM/DS, con el fin de poder replicarlos en otras instancias similares.': 'aquellas prácticas educativas que dan como resultado el logro de los objetivos buscados, definiendo el nivel de impacto en relación a las posibilidades que dan para la participación del estudiante con DM/DS, con el fin de poder replicarlos en otras instancias similares.',
  'Retroalimentar': 'Retroalimentar',
  'al usuario del instrumento a través de la información que arroja, permitiéndole la toma de decisiones y el seguimiento según los principios sobre el que se sostiene el mismo.': 'al usuario del instrumento a través de la información que arroja, permitiéndole la toma de decisiones y el seguimiento según los principios sobre el que se sostiene el mismo.',
  'Indicadores de calidad de Perkins - Características generales': 'Indicadores de calidad de Perkins - Características generales',
  'Los indicadores están organizados en siete categorías de acuerdo a los tópicos que se evalúan.': 'Los indicadores están organizados en siete categorías de acuerdo a los tópicos que se evalúan.',
  'Categorías de indicadores': 'Categorías de indicadores',
  'Categoría 1 al 4: estas categorías agrupan indicadores que se focalizan en la escena más general de la escuela. A través de ellos se puede visibilizar participación, decisiones y organización que, a través de los acuerdos articulados, nos acercan a entender aspectos generales de la cultura escolar': 'Categoría 1 al 4: estas categorías agrupan indicadores que se focalizan en la escena más general de la escuela. A través de ellos se puede visibilizar participación, decisiones y organización que, a través de los acuerdos articulados, nos acercan a entender aspectos generales de la cultura escolar',
  'Categorías del 5 al 7: estas categorías si bien contribuyen a un análisis general orientan la observación de individualidades situadas en la relación maestro-estudiante-familia de personas con DM/DS.': 'Categorías del 5 al 7: estas categorías si bien contribuyen a un análisis general orientan la observación de individualidades situadas en la relación maestro-estudiante-familia de personas con DM/DS.',
  '1- Conocimiento sobre el estudiante: conocimiento, toma de decisiones e instrumentación de herramientas para recolectar información sobre el estudiante. Esto permite valorarlo y a partir de ello diseñar planes de trabajo.': '1- Conocimiento sobre el estudiante: conocimiento, toma de decisiones e instrumentación de herramientas para recolectar información sobre el estudiante. Esto permite valorarlo y a partir de ello diseñar planes de trabajo.',
  '2- Planificación, actividades y contenidos: vinculación de actividades, propuestas, estrategias y contenidos con las demandas y necesidades educativas.': '2- Planificación, actividades y contenidos: vinculación de actividades, propuestas, estrategias y contenidos con las demandas y necesidades educativas.',
  '3- Organización del entorno educativo: aspectos sobre la organización del entorno en el que se desarrolla la práctica educativa a partir de la administración de variables tiempo y contexto.': '3- Organización del entorno educativo: aspectos sobre la organización del entorno en el que se desarrolla la práctica educativa a partir de la administración de variables tiempo y contexto.',
  '4- Gestión escolar: determinaciones sobre la disposición de los recursos humanos, implementación de estrategias y trabajo en equipo para el desarrollo de los programas educativos de los estudiantes.': '4- Gestión escolar: determinaciones sobre la disposición de los recursos humanos, implementación de estrategias y trabajo en equipo para el desarrollo de los programas educativos de los estudiantes.',
  '5- Sobre los profesionales: indicadores sobre el conocimiento específico de los profesionales para dar respuesta a las necesidades de los estudiantes, las dinámicas de trabajo colaborativo implementadas y la participación de la familia.': '5- Sobre los profesionales: indicadores sobre el conocimiento específico de los profesionales para dar respuesta a las necesidades de los estudiantes, las dinámicas de trabajo colaborativo implementadas y la participación de la familia.',
  '6- Sobre la participación de los estudiantes: comportamientos, manifestaciones e interacciones comunicativas observados en los estudiantes que son producto de las estrategias, decisiones y planes implementados en la escuela.': '6- Sobre la participación de los estudiantes: comportamientos, manifestaciones e interacciones comunicativas observados en los estudiantes que son producto de las estrategias, decisiones y planes implementados en la escuela.',
  '7- Sobre la familia: participación de la familia en los diferentes niveles y como miembros activos del equipo escolar.': '7- Sobre la familia: participación de la familia en los diferentes niveles y como miembros activos del equipo escolar.',
  'Niveles de logro definidos por el resultado alcanzado': 'Niveles de logro definidos por el resultado alcanzado',
  'Para cumplimentar con los objetivos se definen los siguientes niveles de impacto': 'Para cumplimentar con los objetivos se definen los siguientes niveles de impacto',
  'se identifican prácticas, acciones, decisiones o materiales que han sido implementados adecuadamente sólo en algún aspecto o por alguna persona de la comunidad educativa. Se desarrollan en un nivel específico y en tiempos discontinuos.': 'se identifican prácticas, acciones, decisiones o materiales que han sido implementados adecuadamente sólo en algún aspecto o por alguna persona de la comunidad educativa. Se desarrollan en un nivel específico y en tiempos discontinuos.',
  'se identifican prácticas, acciones, decisiones o materiales implementados adecuadamente, en forma discontinua pero recurrente, realizadas por varias personas y en diversas áreas o escenarios.': 'se identifican prácticas, acciones, decisiones o materiales implementados adecuadamente, en forma discontinua pero recurrente, realizadas por varias personas y en diversas áreas o escenarios.',
  'se identifican prácticas, acciones, decisiones o materiales implementados adecuadamente y en forma más sistemática y periódica. Se apoya en diferentes niveles de gestión e involucra a varios actores, por lo que alcanza a la mayoría de las áreas o escenarios/espacios.': 'se identifican prácticas, acciones, decisiones o materiales implementados adecuadamente y en forma más sistemática y periódica. Se apoya en diferentes niveles de gestión e involucra a varios actores, por lo que alcanza a la mayoría de las áreas o escenarios/espacios.',
  'se identifican decisiones, acciones, proyectos de intención y prácticas institucionales innovadoras documentadas que involucran a todas las áreas y espacios de la escuela y a todos los miembros de la comunidad educativa.': 'se identifican decisiones, acciones, proyectos de intención y prácticas institucionales innovadoras documentadas que involucran a todas las áreas y espacios de la escuela y a todos los miembros de la comunidad educativa.',
  'existe evidencia de decisiones, acciones y proyectos orientados a la creación y desarrollo de estrategias innovadoras en forma habitual. Esto define un rasgo identitario particular de liderazgo en relación a otras prácticas, instituciones y al impacto en la comunidad.': 'existe evidencia de decisiones, acciones y proyectos orientados a la creación y desarrollo de estrategias innovadoras en forma habitual. Esto define un rasgo identitario particular de liderazgo en relación a otras prácticas, instituciones y al impacto en la comunidad.',
  'NOTA: LA VALORACIÓN DE UN INDICADOR EN UN NIVEL SUPONE QUE TODOS LOS NIVELES ANTERIORES HAN SIDO SUPERADOS.': 'NOTA: LA VALORACIÓN DE UN INDICADOR EN UN NIVEL SUPONE QUE TODOS LOS NIVELES ANTERIORES HAN SIDO SUPERADOS.',
  'Implementación': 'Implementación',
  'La aplicación puede seguir dos estrategias: la observación directa Y/o la entrevista. Cada estrategia se complementa entre sí para poder recoger la información necesaria.': 'La aplicación puede seguir dos estrategias: la observación directa Y/o la entrevista. Cada estrategia se complementa entre sí para poder recoger la información necesaria.',
  'La información obtenida de la observación y/o de la entrevista se introduce en una hoja de cálculo en un proceso similar al seguido con la versión anterior de los PQI (2018). Cada criterio de evaluación se valora por separado según los diferentes niveles de logro. La herramienta calcula una puntuación para cada criterio, indicador y categoría, para posteriormente estimar el progreso general de un programa.': 'La información obtenida de la observación y/o de la entrevista se introduce en una hoja de cálculo en un proceso similar al seguido con la versión anterior de los PQI (2018). Cada criterio de evaluación se valora por separado según los diferentes niveles de logro. La herramienta calcula una puntuación para cada criterio, indicador y categoría, para posteriormente estimar el progreso general de un programa.',
  'Bienvenidos a la Aplicación Evaluadora de Indicadores de Calidad de Perkins': 'Bienvenidos a la Aplicación Evaluadora de Indicadores de Calidad de Perkins',
  'Ingresa con tu cuenta': 'Ingresa con tu cuenta',
  'Email o usuario': 'Email o usuario',
  '¿Olvidaste tu contraseña?': '¿Olvidaste tu contraseña?',
  'Iniciar sesión': 'Iniciar sesión',
  'Políticas de Privacidad': 'Políticas de Privacidad',
  'Ingresa tu dirección de email y te enviaremos un link de recuperación': 'Ingresa tu dirección de email y te enviaremos un link de recuperación',
  'Enviar email': 'Enviar email',
  'Volver': 'Volver',
  '¿Estás seguro de eliminar esta nota': '¿Estás seguro de eliminar esta nota',
  '¿Estás seguro de eliminar esta respuesta': '¿Estás seguro de eliminar esta respuesta',
  '¿Estás seguro de eliminar esta meta': '¿Estás seguro de eliminar esta meta',
  '¿Estás seguro de eliminar esta actividad': '¿Estás seguro de eliminar esta actividad',
  '¿Estás seguro de eliminar este resultado': '¿Estás seguro de eliminar este resultado',
  '¿Estás seguro de eliminar esta observación' : '¿Estás seguro de eliminar esta observación',
  'Preguntas y respuestas' : 'Preguntas y respuestas',
  'Metas y actividades' : 'Metas y actividades',

  //user validations
  'Debe tener un mínimo de 8 caracteres': 'Debe tener un mínimo de 8 caracteres',
  'Ya existe un usuario registrado con este nombre.' : 'Ya existe un usuario registrado con este nombre.',
  'Las contraseñas no coinciden':'Las contraseñas no coinciden',
  'Ha ocurrido un error, intente nuevamente más tarde' : 'Ha ocurrido un error, intente nuevamente más tarde',
  'Ya existe una cuenta registrada con este correo electrónico.' : 'Ya existe una cuenta registrada con este correo electrónico.',
  'There is already a representative for the selected country' : 'Ya existe un representante para el país seleccionado.',
  'There is already a representative for the selected region' : 'Ya existe un representante para la región seleccionada.',
  'There is already a representative for the selected school' : 'Ya existe un representante para la escuela seleccionada.',
  'Debe asignar al menos un elemento' : 'Debe asignar al menos un elemento',
  'You must specify a country' : 'Debe especificar algun país',
  'You must specify a region' : 'Debe especificar alguna región',
  'You must specify a school' : 'Debe especificar alguna escuela',
  'Nombre del archivo demasiado largo': 'Nombre del archivo demasiado largo',
  'The file extension is not valid': 'La extensión del archivo no es válida',
  'The file exceeds the size allowed for its extension': 'El archivo excede el tamaño permitido para su extensión',

  //Alerts from backend/ validations
  'You do not have permission to delete this comment.' : 'No tiene permisos para eliminar este comentario',
  'Ensure this field has no more than 1000 characters.': 'Revise que este campo no tenga mas de 1000 caracteres.',
  'Ensure this field has at least 5 characters.' : 'Asegurese que este campo tenga al menos 5 caracteres.',
  'Demasiados caracteres' : 'Demasiados caracteres.',
  'Ocurrió un problema, vuelva a intentar más tarde': 'Ocurrió un problema, vuelva a intentar más tarde',
  'This field may not be blank.': 'Este campo no puede estar en blanco.',
  'This password is too short. It must contain at least 8 characters.': 'Esta contraseña es demasiado corta. Debe contener al menos 8 caracteres.',
  'Ensure this field has at least 8 characters.': 'Asegúrese de que este campo tenga al menos 8 caracteres.',

  //otras
  'Ingrese un mínimo de 3 caracteres' : 'Ingrese un mínimo de 3 caracteres',
  '¿Eliminar el archivo:' : '¿Eliminar el archivo:',
  'No hay archivos cargados' : 'No hay archivos cargados',
  'Descarga clickeando en los iconos de los archivos': 'Descarga clickeando en los iconos de los archivos',
  'Link de recuperación enviado a la dirección de email ingresada.': 'Link de recuperación enviado a la dirección de email ingresada.',
  'No existe usuario vinculado con el email ingresado.': 'No existe usuario vinculado con el email ingresado.',
  'Recuperar contraseña': 'Recuperar contraseña',
  'Token inválido o expirado': 'Token inválido o expirado',
  'Por favor intentelo con un nuevo token' : 'Por favor intentelo con un nuevo token',
  'Ingresa tu nueva contraseña' : 'Ingresa tu nueva contraseña',
  'Nueva contraseña' : 'Nueva contraseña',
  'Cambiar': 'Cambiar',
  'Imprimir sección': 'Imprimir sección',
  '¿Cambiar contraseña?' : '¿Cambiar contraseña?',
  'cambiar rol': 'cambiar rol',
  'cambiar el rol eliminaría relaciones con paises, regiones y escuelas.' : 'cambiar el rol eliminaría relaciones con paises, regiones y escuelas.',
  'El archivo que se desea cargar debe tener alguna de las siguientes extensiones y tamaños máximos': 'El archivo que se desea cargar debe tener alguna de las siguientes extensiones y tamaños máximos',
  'para': 'para',
  'Escuela no encontrada.': 'Escuela no encontrada.',

  //Modal impresion
  '¿Problemas para imprimir los gráficos': '¿Problemas para imprimir los gráficos',
  'Al presionar el botón de imprimir y dirigirse a la interfaz de impresión, puede ver diferencias en el tamaño y disposicion de los gráficos y de la tipografía, según el navegador web que usted utilize (recomendamos utilizar Chrome o Firefox).': 'Al presionar el botón de imprimir y dirigirse a la interfaz de impresión, puede ver diferencias en el tamaño y disposicion de los gráficos y de la tipografía, según el navegador web que usted utilize (recomendamos utilizar Chrome o Firefox).',
  'También puede haber variaciones segun el zoom que haya fijado en su navegador, se recomienda usar el valor por defecto (100%) al momento de presionar el botón de "imprimir gráficos".': 'También puede haber variaciones segun el zoom que haya fijado en su navegador, se recomienda usar el valor por defecto (100%) al momento de presionar el botón de "imprimir gráficos".',
  'En cualquiera de los casos, cuando presione el botón, se abrira el modal de impresión que es un componente que no depende de nuestra aplicación, sino del navegador.': '',
  'En todos los navegadores encontrará aqui una versión distinta de esta funcionalidad, pero todos poseen algunas funciones útiles con las cuales puede darle mas personalización a la disposicion y tamaño de los elementos.': '',
  'Por ejemplo, puede ajustar los márgenes de la página, seleccionar si desea imprimir en orientación vertical u horizontal, y decidir si desea imprimir los encabezados y pies de página del navegador.': '',
  'Además, algunos navegadores ofrecen la opción de escalar el contenido de la página para que se ajuste al tamaño del papel. Esto puede ser útil si encuentra que el contenido de la página es demasiado grande o demasiado pequeño cuando se imprime.': '',
  'Ejemplo en Google Chrome': '',

  //Reports
  'Reportes': 'Reportes',
  'Reporte': 'Reporte',
  'Time': 'Hora',
  'Acuerdos': 'Acuerdos',
  'Crear reporte': 'Crear reporte',
  '¿Estás seguro de eliminar este reporte': '¿Estás seguro de eliminar este reporte',
  'No hay reportes asignados para este país.' : 'No hay reportes asignados para este país.',
  'No hay reportes asignados para esta región.' : 'No hay reportes asignados para esta región.',

}
